.main {
  margin-left: 110px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}

@keyframes glitch {
  0% {
    clip: rect(44px, 9999px, 56px, 0);
    transform: skew(0.08deg);
  }
  10% {
    clip: rect(27px, 9999px, 97px, 0);
    transform: skew(0.15deg);
  }
  20% {
    clip: rect(19px, 9999px, 59px, 0);
    transform: skew(0.5deg);
  }
  30% {
    clip: rect(68px, 9999px, 13px, 0);
    transform: skew(0.12deg);
  }
  40% {
    clip: rect(82px, 9999px, 56px, 0);
    transform: skew(0.18deg);
  }
  50% {
    clip: rect(46px, 9999px, 91px, 0);
    transform: skew(0.22deg);
  }
  60% {
    clip: rect(69px, 9999px, 25px, 0);
    transform: skew(0.06deg);
  }
  70% {
    clip: rect(86px, 9999px, 12px, 0);
    transform: skew(0.09deg);
  }
  80% {
    clip: rect(97px, 9999px, 48px, 0);
    transform: skew(0.15deg);
  }
  90% {
    clip: rect(74px, 9999px, 65px, 0);
    transform: skew(0.03deg);
  }
  100% {
    clip: rect(29px, 9999px, 85px, 0);
    transform: skew(0.02deg);
  }
}

.glitch-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  animation: glitch 2s linear infinite;
  pointer-events: none;
}

