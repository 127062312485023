/* Estilos existentes */

aside {
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--body-color);
  border-right: 1px solid var(--border-color);
  padding: 2.5rem;
  width: 110px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 11;
  transition: 0.3s;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__link {
  font-size: 1.5rem;
  color: var(--title-color);
  font-weight: var(--font-bold);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center; /* Para centrar el icono */
}

.nav__link:hover {
  color: hsl(43, 100%, 68%);
}

.nav__icon {
  transition: transform 0.3s;
}

.nav__link:hover .nav__icon {
  transform: scale(2.5); /* Agranda el icono al pasar el mouse */
}

.footer__button {
  background-color: transparent;
}

.copyright {
  color: var(--title-color);
  font-size: var(--small-font-size);
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
}

.nav__toggle {
  position: fixed;
  top: 1.25rem;
  left: 1.875rem;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--body-color);
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.3s;
  font-size: 1rem;
}

[data-theme="dark"] .nav__toggle {
  border: 1px solid var(--title-color);
}

[data-theme="dark"] .nav__toggle > svg {
  color: var(--title-color);
}

@media screen and (max-width: 1024px) {
  .aside {
    left: -110px;
  }

  .nav__toggle {
    display: flex;
  }

  .nav__toggle-open {
    left: 140px;
  }

  .show-menu {
    left: 0;
  }
}
