@keyframes pixelate {
  0% {
    transform: scale(1);
    filter: contrast(1) brightness(1) blur(0) saturate(1);
  }
  25% {
    transform: scale(1.1);
    filter: contrast(1.5) brightness(1.5) blur(2px) saturate(1.5);
  }
  50% {
    transform: scale(0.9);
    filter: contrast(0.5) brightness(0.5) blur(4px) saturate(0.5);
  }
  75% {
    transform: scale(1.2);
    filter: contrast(2) brightness(2) blur(6px) saturate(2);
  }
  100% {
    transform: scale(1);
    filter: contrast(1) brightness(1) blur(0) saturate(1);
  }
}

.pixel-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  animation: pixelate 2s linear;
  pointer-events: none;
}
.loading{
  color: hsl(43, 100%, 68%);
}
