@font-face {
  font-family: 'MiFuente';
  src: url('/src/assets/Riviera.otf') format('opentype');
}

.home {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro {
  max-width: 540px;
  text-align: center;
  z-index: 10;
}

.home__img {
  margin-bottom: 1.5rem;
}

.home__name {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  margin-bottom: 1.5rem;
}

.home__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5.75rem;
  margin: 3.0rem 0;
}

.home__social-link {
  color: var(--title-color);
  font-size: 1.8rem;
  transition: 0.3s;
}

.home__social-link:hover {
  color: var(--profession-color);
  transform: scale(2.5);
}

.home__social-link svg {
  width: 2rem;
  height: 2rem;
}

.profession {
  margin-top: 15px;
}

h6 {
  color: var(--profession-color);
  font-family: 'MiFuente';
  line-height: 1.5;
  font-size: 30px;
  height: 80px; 
  align-items: center; 
  justify-content: center;
}

.scroll__down {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  width: 100%;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.mouse {
  border: 2px solid #454360;
  display: block;
  height: 1.6rem;
  width: 1.25rem;
  margin: auto;
  margin-top: 0.75rem;
  border-radius: 1rem;
  position: relative;
}

@keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}

.wheel {
  background-color: var(--title-color);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  animation: ani-mouse 2s linear infinite;
}

.shapes {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.shape {
  position: absolute;
}

.s1 {
  left: 2%;
  top: 10%;
}

.s2 {
  left: 18%;
  top: 30%;
}

.s3 {
  left: 5%;
  bottom: 30%;
}

.s4 {
  left: 2%;
  bottom: 10%;
}

.s5 {
  left: 44%;
  top: 10%;
}

.s6 {
  left: 36%;
  bottom: 10%;
}

.s7 {
  top: 20%;
  right: 25%;
}

.s8 {
  right: 24%;
  bottom: 20%;
}

.s9 {
  right: 2%;
  top: 10%;
}

.s10 {
  top: 45%;
  right: 11%;
}

.s11 {
  bottom: 10%;
  right: 2%;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .intro {
    max-width: 90%;
  }
  .home__socials {
    column-gap: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .home__name {
    font-size: 2rem;
  }
  .home__social-link {
    font-size: 1.5rem;
  }
  .home__social-link:hover {
    transform: scale(2);
  }
  .home__social-link svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .home__scroll-name {
    font-size: var(--small-font-size);
  }
}

@media screen and (max-width: 480px) {
  .home__name {
    font-size: 1.5rem;
  }
  .home__social-link {
    font-size: 1.2rem;
  }
  .home__social-link:hover {
    transform: scale(1.5);
  }
  .home__social-link svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  .intro {
    padding: 0 1rem;
  }
  .scroll__down {
    bottom: 1.5rem;
  }
}

/* Ensure hover effect works on touch devices */
@media (hover: none) {
  .home__social-link:hover {
    transform: none;
  }
}

@media (hover: hover) {
  .home__social-link:hover {
    transform: scale(2.5);
  }
}
