.footer {
    background-color: var(--body-color);
    color: var(--title-color);
    padding: 1rem;
    text-align: center;
    border-top: 1px solid var(--border-color);
    position: relative;
    bottom: 0;
    width: 100%;
}
